<template>
  <embed
    src="/APK1.pdf"
    type="application/pdf"
    width="100%"
    height="1000px"
  />
</template>

<script>
/* eslint-disable */
export default {
  name: 'Report',
}
</script>
